<template>
  <div>
    <section class="d-flex justify-content-between align-items-end mb-1">
      <div>
        <label for="search">{{ $t('general.search') }}:</label>
        <b-form-input
          v-model="query_params.search"
          :placeholder="$t('general.search')"
          @input="delayedSearch"
        />
      </div>
      <b-button
        v-b-modal.subject-modal
        variant="primary"
        @click="openNewSubjectModal"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t('general.add') }}
      </b-button>
    </section>
    <b-card>
      <b-table
        id="subjects-table"
        striped
        :fields="fields"
        :items="GET_SUBJECTS_LIST.results"
      >
        <template #head()="{ label }">
          {{ $t(label) }}
        </template>
        <template #cell(title)="{ item }">
          {{ item.title[$i18n.locale] }}
        </template>
        <template #cell(actions)="{ item }">
          <div class="d-flex justify-content-around">
            <b-button
              v-b-modal.subject-modal
              class="p-50"
              variant="warning"
              @click="editBtnClicked(item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              class="p-50"
              variant="danger"
              @click="deleteBtnClicked(item.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
      <custom-pagination
        :query-params="query_params"
        :total-rows="GET_SUBJECTS_LIST.count"
        @changePage="changePage"
        @changePerPage="changePerPage"
      />
    </b-card>
    <default-add-modal
      id="subject-modal"
      :is-edit="is_edit"
      :edit-data="subject"
      @add-button="saveSubject"
    />
  </div>
</template>

<script>
import {
  BCard, BTable, BButton, BFormInput,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import customPagination from '@/components/customPagination.vue'
import defaultAddModal from '@/components/DefoultAddModal.vue'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    customPagination,
    BFormInput,
    defaultAddModal,
  },
  data() {
    return {
      query_params: {
        page: 1,
        page_size: 10,
        search: '',
      },
      fields: [
        {
          key: 'title',
          label: 'general.title',
        },
        {
          key: 'actions',
          label: 'general.actions',
          tdClass: 'width-150',
        },
      ],
      is_edit: false,
      subject: {
        title: {},
      },
    }
  },
  computed: {
    ...mapGetters('knowledgeBase', ['GET_SUBJECTS_LIST']),
  },
  mounted() {
    this.FETCH_SUBJECTS_LIST()
  },
  methods: {
    ...mapActions('knowledgeBase', ['FETCH_SUBJECTS_LIST', 'ADD_SUBJECT', 'EDIT_SUBJECT']),
    saveSubject(subject) {
      if (this.is_edit) {
        this.EDIT_SUBJECT(subject).then(() => {
          this.$_successToast(this.$t('general.success'))
          this.$bvModal.hide('subject-modal')
          this.refresh()
        })
      } else {
        this.ADD_SUBJECT(subject).then(() => {
          this.$_successToast(this.$t('general.success'))
          this.$bvModal.hide('subject-modal')
          this.refresh()
        })
      }
    },
    delayedSearch() {
      clearTimeout(this.delay)
      this.delay = setTimeout(() => {
        this.refresh()
      }, 300)
    },
    refresh() {
      this.FETCH_SUBJECTS_LIST(this.query_params)
    },
    openNewSubjectModal() {
      this.is_edit = false
    },
    editBtnClicked(subject) {
      this.is_edit = true
      this.subject = subject
    },
    changePage(val) {
      this.query_params.page = val
      this.refresh()
    },
    changePerPage(val) {
      this.query_params.page_size = val
      this.refresh()
    },
    deleteBtnClicked(id) {
      this.$_showAreYouSureModal().then(res => {
        if (res) {
          this.EDIT_SUBJECT({ is_active: false, id }).then(() => {
            this.$_successToast(this.$t('general.success'))
            this.refresh()
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
